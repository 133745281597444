<template>
    <div class='content'>
        <div class='qrCode'></div>
        <div class='contentDetail'>
            <div class='contentFl'>
                <div class='name'>南繁硅谷云</div>
                <p class='first'>该功能仅支持手机使用，给您带来不便还请谅解!</p>
                <p>请扫码下载 APP</p>
                <div class='code flex'>
                    <div class='codeItem'>
                        <img class='codeImg' src="../../../public/static/img/tipsBlock/ios.jpg" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    
};
</script>
<style lang="less" scoped>
    .qrCode{
        background: url('../../assets/images/qrCode/bg.png')  no-repeat center;;
        width:100%;
        height:100%;
        position: absolute;
        left:0;
        top:0;
    }
    .content{
        min-height:598px;
        z-index:-100px;
        position: relative;
    }
    .contentDetail{
        position: relative;
        z-index:100px;
    }
    .contentFl{
        margin:0 auto;
        top:160px;
        position: absolute;
        right:50%;
        margin-right: -300px;
        .name{
            color: #448afc;
            font-size:40px;
        }
        p{
            color: #333;
            font-size: 16px;
        }
        .first{
            margin:20px 0 40px;
            font-size: 18px;
        }
        .code{
            margin-top:20px;
        }
        .codeItem{
            width: 148px;
            height: 148px;
            border-radius: 4px;
            border: solid 1px #b2b2b2;
            text-align: center;
            
            .codeImg{
                margin: 31px 0 20px;
                width: 70px;
                height: 70px;
            }
            img{
                margin-right:11px;
                width: 21px;
                height: 24px;
            }
            div{
                justify-content: center;
            }
        }

    }
    
    
</style>